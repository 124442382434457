<template>
  <v-container>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon medium> mdi-file-document </v-icon>
          รายงาน
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">ลำดับที่</th>
                <th class="text-center">ชื่อรายงาน</th>
                <th class="text-center">รายงาน</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in reports" :key="item.id">
                <td class="text-center">{{ item.id }}</td>
                <td class="text-center">{{ item.name }}</td>
                <td class="text-center">
                  <v-btn
                    small
                    color="secondary"
                    class="ma-2"
                    outlined
                    :to="item.link"
                  >
                    <v-icon>mdi-file-document </v-icon>
                    รายงาน
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
  
  <script>
export default {
  name: "Report",
  components: {},

  data() {
    return {
      reports: [
        {
          id: 1,
          name: "รายได้ลูกค้ารวมทุกบริการ (ตาม CA)",
          link: "/report/price-sum-by-ca",
        },
      ],
    };
  },
};
</script>

  <style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>